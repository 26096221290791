
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted,onActivated } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { boolean } from "yup/lib/locale";

import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";





export default defineComponent({
    name: "order-maintenance-modal",
    emit: ["refreshData"],
    props: {
        maintenance: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        Datepicker,
        FieldError,
    },
    data () {
      return {
        // value: null,
        // empl: ['list', 'of', 'options']
      }
    },

    setup(props, { emit }) {
        const { t, te } = useI18n();

        let modal = ref();
        let parks = ref([]);
        let suppliers = ref([]);
        let supplierEmployees = ref([]);

        let errors: any = ref();

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const orderMaintenanceModalRef = ref<null | HTMLElement>(null);
        const requestMaintenanceOrderDefault = {
            id                     :null,
            description            :"",
            date_begin             :"",
            date_end               :"",
            hour                   :"",
            park_id                :0,
            supplier_id            :"",
            supplier_employee_id   :null,
            execution_type         :1,
            employee_name          :" ",
            is_validated           :'0',
            state                  :Number

        };
        const requestMaintenanceOrderData = ref<any>({});
        Object.assign(requestMaintenanceOrderData.value, requestMaintenanceOrderDefault);


        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };


        const validationSchema = Yup.object().shape({
            // date: Yup.string().required().label(translate("orderMaintenanceBeginDateLabel")),
            // date_end: Yup.string().required().label(translate("orderMaintenanceEndDateLabel")),
            // description: Yup.string().label(translate("requestMaintenanceDescriptionLabel")),

        });


        const submit = (values, actions) => {
            errors.value = null;


            if (requestMaintenanceOrderData.value.is_validated == '1') {
                requestMaintenanceOrderData.value.state = 3;

            }
            else{
                requestMaintenanceOrderData.value.state = 1;
            }

            if (!submitButtonRef.value) {
                // requestMaintenanceOrderData.value.state = 3;
                return;
            }



            // Disable buttons
            // submitButtonRef.value.disabled = true;

            // // Activate indicator
            // submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            requestMaintenanceOrderData.value.id = props.maintenance.id;


            if (requestMaintenanceOrderData.value.supplier_employee_id) {

                requestMaintenanceOrderData.value.supplier_employee_id = requestMaintenanceOrderData.value.supplier_employee_id.id;

            }else{
                requestMaintenanceOrderData.value.supplier_employee_id = null;

            }

            // save the actual hour before formating and sending it to the backend
            var h = requestMaintenanceOrderData.value.hour;


            if (requestMaintenanceOrderData.value.hour) {

                if (requestMaintenanceOrderData.value.hour.hours >= 0 &&
                    requestMaintenanceOrderData.value.hour.hours <= 9 &&
                    requestMaintenanceOrderData.value.hour != props.maintenance.hour)
               {
                requestMaintenanceOrderData.value.hour.hours = '0' + requestMaintenanceOrderData.value.hour.hours;

               }

                if (requestMaintenanceOrderData.value.hour.minutes >= 0 &&
                    requestMaintenanceOrderData.value.hour.minutes <= 9 &&
                    requestMaintenanceOrderData.value.hour != props.maintenance.hour)
                {
                    requestMaintenanceOrderData.value.hour.minutes = '0'+requestMaintenanceOrderData.value.hour.minutes;

                }

                requestMaintenanceOrderData.value.hour = requestMaintenanceOrderData.value.hour.hours+':'+requestMaintenanceOrderData.value.hour.minutes

            }





            requestMaintenanceOrderData.value.date_begin = dateFormat(requestMaintenanceOrderData.value.date_begin );
            requestMaintenanceOrderData.value.date_end = dateFormat(requestMaintenanceOrderData.value.date_end );

            // if (requestMaintenanceOrderData.park_id && props.maintenance) {
            //     requestMaintenanceOrderData.park_id = requestMaintenanceOrderData.park_id.id;
            // }

            // disableSubmitButton(submitButtonRef);

            axios.post(ApiRoutes.maintenances.order,requestMaintenanceOrderData.value)
                .then((response: AxiosResponse) => {

                    // enableSubmitButton(submitButtonRef);

                    // requestMaintenanceOrderData.value.hour = h;

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }



                    if (response.data && response.data.success) {


                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(orderMaintenanceModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    // if (submitButtonRef.value) {
                    //     submitButtonRef.value.disabled = false;

                    //     submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    // }
                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                    }
                });


        };

        const getExecType = async (value) => {

        value == 1 ? getParks() : getSuppliers() ;

        };

        const getParks = async () => {
           const response = await axios.get(ApiRoutes.parks.list);

           parks.value = response.data;
           if (! props.maintenance.supplier_employee_id) {
               const element: HTMLElement = document.getElementById('select2-supplierEmplooyeeId-container') as HTMLElement
               element.innerHTML = translate('search') + '...';

           }

           suppliers.value=[];
           supplierEmployees.value = [];


        };

        const getEmp = async () => {
           var response = await axios.get(ApiRoutes.employees.read,{ params: { id: requestMaintenanceOrderData.value.park_id } });

           requestMaintenanceOrderData.value.employee_name =  response.data;



        };

        const getSuppliers = async () => {
           const response = await axios.get(ApiRoutes.suppliers.list);

           suppliers.value = response.data;
           if (! props.maintenance.park_id) {
           requestMaintenanceOrderData.value.employee_name = "";
           }

           parks.value=[];

        };

        const getSupplierEmployees = async (name) => {
            const response = await axios.get(ApiRoutes.employeesSupplier.list, { params: { type: 1, name: name } });
            supplierEmployees.value = response.data;

            requestMaintenanceOrderData.value.supplier_employee_id = {};
            const element: HTMLElement = document.getElementById('select2-supplierEmplooyeeId-container') as HTMLElement
            element.innerHTML = translate('search') + '...';


        };

        const updateState = async () => {
            requestMaintenanceOrderData.value.is_validated ='1';

            const saveBtn: HTMLElement = document.getElementById('kt_modal_order_maintenance_submit') as HTMLElement
            saveBtn.click();

        };

        const dateFormat = (value) => {
            return moment(value).format('YYYY-MM-DD');
        };




        onMounted(() => {



            // getSupplierEmployees();

             modal.value = document.getElementById('kt_modal_order_maintenance');

            if (modal.value) modal.value.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(requestMaintenanceOrderData.value, requestMaintenanceOrderDefault);
            });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', function() {

                if (props.maintenance) {

                    if (props.maintenance.park_id) {
                        requestMaintenanceOrderData.value.park_id = props.maintenance.park_id;
                        getParks();

                        requestMaintenanceOrderData.value.park_id = props.maintenance.park_id;
                        getEmp();
                    }

                    if (props.maintenance.supplier_id) {
                        requestMaintenanceOrderData.value.supplier_id = props.maintenance.supplier_id;
                        getSuppliers();

                        // getExecType(2);

                        const element: HTMLElement = document.getElementById('select2-supplierEmplooyeeId-container') as HTMLElement
                        element.innerHTML = props.maintenance.supplier_employee_id.text;


                    }

                    if (props.maintenance.state == 3) {
                         requestMaintenanceOrderData.value.is_validated = '1';

                    }

                    // Fill Intra fields
                    Object.keys(props.maintenance).forEach((key) => {
                        if (requestMaintenanceOrderDefault.hasOwnProperty(key)) requestMaintenanceOrderData.value[key] = props.maintenance[key];
                    });

                }
            });


            // loadJquery for employee_id
            jQuery("#supplierEmplooyeeId").select2({
                dropdownParent: modal.value,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employeesSupplier.list,
                    data: function (params) {
                        return {
                            q: params.term,
                            id:requestMaintenanceOrderData.value.supplier_id,
                            // exclude: requestMaintenanceOrderData.value.supplier_employee.map(a => a.id),
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                requestMaintenanceOrderData.value.supplier_employee_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };

                    requestMaintenanceOrderData.value.supplier_employee_id.push(data);
                // }

                jQuery("#supplierEmplooyeeId").val('').trigger('change');
            });

            // end load jQuery for supplier_employee_id




            // load jquery for park !!!

            // jQuery("#requestMaintenancePark").select2({
            //     dropdownParent: modal.value,
            //     minimumInputLength: 1,
            //     placeholder: translate('search') + '...',
            //     ajax: {
            //         dataType: 'json',
            //         delay: 250,
            //         url: ApiRoutes.parks.list,
            //         data: function (params) {
            //             return {
            //                 q: params.term,
            //                 code:'D01',
            //                 // exclude: requestMaintenanceOrderData.value.supplier_employee.map(a => a.id),
            //             };
            //         },
            //         processResults: function (data, params) {
            //             return {
            //                 results: data
            //             };
            //         },
            //         cache: false
            //     }

            // }).on('select2:select', function(e){
            //     let emp = e.params.data;
            //     requestMaintenanceOrderData.value.park_id = emp;
            //     let data = {
            //         id              : emp.id,
            //         heading         : null,
            //         name            : emp.last_name,
            //     };

            //         requestMaintenanceOrderData.value.park_id.push(data);


            //     jQuery("#requestMaintenancePark").val('').trigger('change');
            // });
            // end load jquery for spare_part_id


        });


        return {
            translate,

            errors,
            resetField,
            checkField,

            getExecType,
            getSuppliers,
            modal,
            getSupplierEmployees,
            updateState,
            getParks,
            getEmp,
            parks,
            supplierEmployees,
            suppliers,
            requestMaintenanceOrderData,
            validationSchema,
            submit,
            submitButtonRef,
            orderMaintenanceModalRef,
            dateFormat,
        };
    },
});
