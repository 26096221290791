
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import Multiselect from '@vueform/multiselect';
import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

export default defineComponent({
    name: "search-employee-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        Multiselect,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let structures = ref([]);
        let types = ref([]);
        let breakdowns = ref([]);
        let employees = ref([]);
        let analyticAccounts = ref([]);
        let employee = [];


        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchEmployeeModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            type_id                 :0,
            emergency               :"",
            descirption             :"",
            breakdown_id            :0,
            analytic_account        :"",
            structure_id            :"",
            employee_id             :0,
            description             :"",
            structure_type          :1,
            analytic_account_type   :1,

        };
        const queryParams = ref(props.params);
        Object.assign(queryParams.value, initialValues);

        // const empl  = ref({
        //     options: employees,
        //     searchable: true,
        //     });

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchEmployeeModalRef.value);
        };

        const getTypes = async () => {
            const response = await axios.get(ApiRoutes.maintenances.types);

            types.value = response.data;
        };

        // get maintenance breakdowns
        const getBreakdowns = async () => {
            const response = await axios.get(ApiRoutes.maintenances.breakdowns);

            breakdowns.value = response.data;
        };


        const getStructures = async () => {
            const level = (queryParams.value) ? queryParams.value.structure_type : 1;
            const response = await axios.get(ApiRoutes.structures.list, { params: { level: level } });

            structures.value = response.data;
        };

        // const getEmployees = async () => {
        //     const response = await axios.get(ApiRoutes.employees.employees);

        //     employees.value = response.data;
        //     // x.value=response.data;
        //     // console.log("xxxe"+x.value)

        // };

        // const getEmployees = async () => {
        //     const response = await axios.get(ApiRoutes.employees.read, { params: { id: props.maintenance.employee_id } });

        //     queryParams.value.employee_id = response.data;
        //     // x.value=response.data;
        //     // console.log("xxxe"+x.value)

        // };

        const getAnalyticAccounts = async () => {

            var response =  (queryParams.value.analytic_account_type == 1) ?  await axios.get(ApiRoutes.analyticAccounts.list)
                                                                                      :  await axios.get(ApiRoutes.analyticAccountsIntra.list);

            analyticAccounts.value = response.data;


        };



        onMounted(() => {

            getTypes();
            getBreakdowns();
            getStructures();
            // getEmployees();
            getAnalyticAccounts();


            const modal = document.getElementById('kt_modal_search_requestMaintenance');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.assign(queryParams.value, props.params);
                }
            });

            // load jquery !!!
            jQuery("#requestMaintenanceEmployeeIdSearch").select2({
                dropdownParent: modal,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.employees,
                    data: function (params) {
                        return {
                            q: params.term,
                            code:queryParams.value.structure_id,
                            // exclude: requestMaintenanceOrderData.value.supplier_employee.map(a => a.id),
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                queryParams.value.employee_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };

                // if (emp.last_name && emp.last_name.length) {

                    // requestMaintenanceOrderData.value.supplier_employee.push(Object.assign({}, data, {heading: `${emp.last_name}`, attributes_array: emp.last_name}));

                // } else {
                    queryParams.value.employee_id.push(data);
                // }

                jQuery("#requestMaintenanceEmployeeIdSearch").val('').trigger('change');
            });
            // en load jquery


        });

        return {
            translate,
            structures,

            // errors,
            resetField,
            checkField,


            types,
            employee,
            employees,
            analyticAccounts,
            // empl,
            breakdowns,
            getStructures,
            queryParams,
            submit,
            submitButtonRef,
            searchEmployeeModalRef,
        };
    },
});
