
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted,onActivated } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";
import Multiselect from '@vueform/multiselect';

import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";


export default defineComponent({
    name: "edit-maintenance-modal",
    emit: ["refreshData"],
    props: {
        maintenance: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        Multiselect,
        FieldError,
    },
    data() {
            return {


        };
    },

    setup(props, { emit }) {
        const { t, te } = useI18n();

        let isUpdate = false as boolean;

        let types = ref([]);
        let breakdowns = ref([]);
        let structures = ref([]);
        let analyticAccounts = ref([]);
        let errors: any = ref();


        let formData = new FormData();


        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editMaintenanceModalRef = ref<null | HTMLElement>(null);
        const requestMaintenanceDefault = {
            id: null,
            type_id:null,
            breakdown_id:null,
            analytic_account:"",
            emergency: "",
            description:"",
            path:"",
            structure_code:"",
            structure_type:1,
            state:-1,
            analytic_account_type: 1,
            employee_id:0,
            selectedFile:File,
        };
        const requestMaintenanceData = ref<any>({});
        Object.assign(requestMaintenanceData.value, requestMaintenanceDefault);


        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };



        const validationSchema = Yup.object().shape({
            // type_id: Yup.number().min(1).required().label(translate("requestMaintenanceTypeIdLabel")),
            // breakdown_id: Yup.number().min(1).required().label(translate("requestMaintenanceBreakdownIdLabel")),
            // structure_code: Yup.string().required().label(translate("requestMaintenanceStructureCodeLabel")),
            // analytic_account_type: Yup.number().required().label(translate("requestMaintenanceanalyticAccountsLabel")),
            // analytic_account: Yup.string().required().label(translate("requestMaintenanceanalyticAccountsLabel")),
            // employee_id: Yup.object().required().label(translate("requestMaintenanceEmployeeIdLabel")),
            // emergency: Yup.string().required().label(translate("requestMaintenanceEmergencyLabel")),
            // description: Yup.string().label(translate("requestMaintenanceDescriptionLabel")),
            // selectedFile: Yup.mixed().required().label(translate("requestMaintenanceFileLabel")),

        });


        const submit = (values, actions) => {
            errors.value = null;

            // if (!submitButtonRef.value) {
            //     return;
            // }

            // Disable button
            // submitButtonRef.value.disabled = true;
            // Activate indicator
            // submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.maintenance.id){
                 requestMaintenanceData.value.id = props.maintenance.id;
                 formData.append('id', requestMaintenanceData.value.id);
            }
            let config = { headers: { 'Content-Type': 'multipart/form-data' } }


            formData.append('type_id', requestMaintenanceData.value.type_id);
            formData.append('breakdown_id', requestMaintenanceData.value.breakdown_id);
            formData.append('structure_code', requestMaintenanceData.value.structure_code);

            if (requestMaintenanceData.value.employee_id) {
                formData.append('employee_id', requestMaintenanceData.value.employee_id.id);

            }else{
                formData.append('employee_id', requestMaintenanceData.value.employee_id);
            }


            formData.append('analytic_account', requestMaintenanceData.value.analytic_account);
            formData.append('description', requestMaintenanceData.value.description);
            formData.append('emergency', requestMaintenanceData.value.emergency)
            formData.append('path', requestMaintenanceData.value.path);

            (requestMaintenanceData.value.state == -1) ? formData.append('state', '0') :



            disableSubmitButton(submitButtonRef);


            axios.post(ApiRoutes.maintenances.updateOrCreate,formData ,config)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);


                    // if (submitButtonRef.value) {
                    //     submitButtonRef.value.disabled = false;

                    //     submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    // }

                    if (response.data && response.data.success) {

                        const element: HTMLElement = document.getElementById('fileName') as HTMLElement
                        element.innerHTML ="";


                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editMaintenanceModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    // if (submitButtonRef.value) {
                    //     submitButtonRef.value.disabled = false;

                    //     submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    // }
                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                    }
                });
        };

        // get maintenance types
        const getTypes = async () => {
            const response = await axios.get(ApiRoutes.maintenances.types);

            types.value = response.data;
        };



        // get maintenance breakdowns
        const getBreakdowns = async () => {
            const response = await axios.get(ApiRoutes.maintenances.breakdowns);

            breakdowns.value = response.data;
        };

        const getStructures = async () => {
            const level = (requestMaintenanceData.value) ? requestMaintenanceData.value.structure_type : 1;
            const response = await axios.get(ApiRoutes.structures.list, { params: { level: level } });

            structures.value = response.data;
        };

        // const getEmployees = async () => {
        //     const response = await axios.get(ApiRoutes.employees.read, { params: { id: props.maintenance.employee_id } });

        //     requestMaintenanceData.value.employee_id = response.data;


        // };

        const getAnalyticAccounts = async () => {

            var response =  (requestMaintenanceData.value.analytic_account_type == 1) ?  await axios.get(ApiRoutes.analyticAccounts.list)
                                                                                      :  await axios.get(ApiRoutes.analyticAccountsIntra.list);

            analyticAccounts.value = response.data;


        };

        // updating the status of the request
        // this function is used when using rejected and accpeted btns
        const updateState = async (btn) => {

            let status = (btn == 'accepted') ? requestMaintenanceData.value.state = '1' : requestMaintenanceData.value.state = '2';

            // requestMaintenanceData.value.state = status;
            formData.append('state',status);
            const savedBtn: HTMLElement = document.getElementById('kt_modal_edit_maintenance_submit') as HTMLElement
            savedBtn.click();

        };

        // file upload config function
        const onFileChanged =  async (event) => {
             console.log(event.target.files[0]);

             if( (event.target.files[0].type != "application/pdf") &&
                 (event.target.files[0].type != "image/png" ) &&
                 (event.target.files[0].type != "image/jpg" ) &&
                 (event.target.files[0].type != "image/jpeg" ) &&
                  (event.target.files[0].size >1000000))

             {
                 Swal.fire({
                            text: "le fichier ne peux pas depasser 1 MB, et vous pouvez charger seulement fichier du type PDF ou IMAGE",
                            icon: "warning",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
             }else {
                    requestMaintenanceDefault.selectedFile=event.target.files[0];
                    formData.append('file',event.target.files[0]);
                    const element: HTMLElement = document.getElementById('fileName') as HTMLElement
                    element.innerHTML = event.target.files[0].name;
             }

        };




        onMounted(() => {
            getTypes();
            getBreakdowns();
            // getStructures();
            // getEmployees();
            // getAnalyticAccounts();

            const modal = document.getElementById('kt_modal_edit_maintenance');


            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(requestMaintenanceData.value, requestMaintenanceDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;

                if (props.maintenance) {
                    isUpdate = true;

                    requestMaintenanceData.value.state = props.maintenance.state;


                    requestMaintenanceData.value.employee_id = props.maintenance.employee_id;

                    const element: HTMLElement = document.getElementById('select2-requestMaintenanceEmployeeId-container') as HTMLElement
                    element.innerHTML = props.maintenance.employee_id.text;

                    requestMaintenanceData.value.analytic_account_type = props.maintenance.analytic_account_type;
                    requestMaintenanceData.value.structure_type = props.maintenance.structure_type;
                    getAnalyticAccounts();
                    getStructures();



                    // Fill Intra fields
                    Object.keys(props.maintenance).forEach((key) => {
                        if (requestMaintenanceDefault.hasOwnProperty(key)) requestMaintenanceData.value[key] = props.maintenance[key];
                    });
                }
            });

            // load jquery !!!
            jQuery("#requestMaintenanceEmployeeId").select2({
                dropdownParent: modal,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.employees,
                    data: function (params) {
                        return {
                            q: params.term,
                            code:'D01',
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                requestMaintenanceData.value.employee_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };

                requestMaintenanceData.value.employee_id.push(data);


                jQuery("#requestMaintenanceEmployeeId").val('').trigger('change');
            });
            // en load jquery


        });


        return {
            translate,

            errors,
            resetField,
            checkField,

            types,
            analyticAccounts,
            breakdowns,
            structures,

            getAnalyticAccounts,
            getStructures,
            // getEmployees,
            onFileChanged,
            updateState,


            requestMaintenanceData,
            validationSchema,
            submit,
            submitButtonRef,
            editMaintenanceModalRef,

        };
    },
});
